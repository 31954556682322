<template>
  <div>
    <button @click.prevent="openNewSupportTicket" class="btn btn-primary mb-4">
      <i class="bi bi-plus-lg me-1 mb-1"></i>Add Ticket
    </button>
    <NewSupportTicket
      name="new-ticket-modal"
      v-model="isNewSupportTicketModalOpen"
      @cancel="closeNewSupportTicketModal"
      @confirm="onSupportTicketCreated"
    />
    <!--begin::Table-->
    <vue-good-table
      mode="remote"
      :total-rows="totalRecords"
      :is-loading="isTableLoading"
      :pagination-options="{ enabled: true }"
      :sort-options="{ enabled: false, initialSortBy: serverParams.sort }"
      :rows="rows"
      :columns="columns"
      :fixed-header="true"
      @page-change="onPageChange"
      @sort-change="onSortChange"
      @column-filter="onColumnFilter"
      @per-page-change="onPerPageChange"
      styleClass="custom-table clickable"
    >
      <template #loading-content class="text-center">Loading...</template>
      <template #emptystate class="text-center">No entry found</template>
      <template #table-row="props">
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <a href="#" @click.prevent="deleteItem(props.row.id)">
            <i class="fas fa-trash text-danger" />
          </a>
        </span>

        <!-- Column: Common -->
        <span v-else-if="props.column.field === 'priority'">
          <span :class="`badge badge-${ticketPriorityToColorClass(props.row.priority)}`">
            {{ ticketPriorityToStr(props.row.priority) }}
          </span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <span
            :class="`badge badge-${ticketStatusToColorClass(props.row.status)}`"
          >
            {{ ticketStatusToStr(props.row.status) }}
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template #pagination-bottom="props">
        <div
          class="d-flex justify-content-between flex-wrap align-items-center"
        >
          <div class="d-flex align-items-center mb-0 mt-1">
            <select
              class="form-control form-control-sm me-3"
              :value="serverParams.perPage"
              @change="
                (e) => onPerPageChange({ currentPerPage: e.target.value })
              "
            >
              <option
                v-for="(option, idx) in ['5', '10', '25', '50', '100']"
                :key="`select-perpage-${idx}`"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
            <span class="text-nowrap"> tickets per page </span>
          </div>
          <div>
            <ul class="pagination">
              <li
                class="page-item previous"
                :class="{ disabled: serverParams.page === 1 }"
              >
                <a
                  @click.prevent="
                    onPageChange({ currentPage: serverParams.page - 1 })
                  "
                  href="#"
                  class="page-link"
                  ><i class="previous"></i
                ></a>
              </li>
              <div
                v-for="idx in Math.ceil(props.total / serverParams.perPage)"
                class="d-flex flex-row align-items-center"
                :key="idx"
              >
                <li
                  class="page-item"
                  :class="{ active: serverParams.page === idx }"
                >
                  <a
                    @click.prevent="props.pageChanged({ currentPage: idx })"
                    href="#"
                    class="page-link"
                    v-if="
                      idx <= 3 ||
                      (idx >= serverParams.page - 1 &&
                        idx <= serverParams.page + 1) ||
                        (idx >= Math.ceil(props.total / serverParams.perPage) - 2)
                    "
                    >{{ idx }}</a
                  >
                </li>
                <p class="mb-0 mt-1 mx-3" v-if="(idx === serverParams.page - 2 && idx > 3) || (idx === serverParams.page + 2 && (idx < Math.ceil(props.total / serverParams.perPage) - 2))">...</p>
              </div>
              <li
                class="page-item next"
                :class="{
                  disabled:
                    serverParams.page ===
                    Math.ceil(props.total / serverParams.perPage),
                }"
              >
                <a
                  @click.prevent="
                    onPageChange({ currentPage: serverParams.page + 1 })
                  "
                  href="#"
                  class="page-link"
                  ><i class="next"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import ApiService from "@/core/services/ApiService";
import NewSupportTicket from "@/components/modals/support-tickets/NewSupportTicket.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import TicketPriority from "@/core/constants/TicketPriority";
import TicketStatus from "@/core/constants/TicketStatus";

export default {
  name: 'TicketsManager',
  components: {
    NewSupportTicket,
  },
  data() {
    return {
      isTableLoading: false,
      totalRecords: 0,
      showDescriptionModal: true,
      isNewSupportTicketModalOpen: false,
      columns: [
				{
					label: "Author",
					field: "author",
					formatFn: (author) =>
						author ? `${author.firstname} ${author.lastname}` : "N/C",
				},
				{
					label: "Priority",
					field: "priority",
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: Object.keys(TicketPriority).map(e => ({
              value: e,
              text: TicketPriority[e].label,
            })),
          },
				},
				{
					label: "Status",
					field: "status",
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: Object.keys(TicketStatus).map(e => ({
              value: e,
              text: TicketStatus[e].label,
            })),
          },
				},
				{
				  label: "Category",
				  field: "category",
          filterOptions: {
            enabled: true,
          }
				},
				{
					label: "Title",
					field: "title",
          filterOptions: {
            enabled: true,
          },
				},
				{
					label: "Description",
					field: "description",
				},
				{
					label: "Created",
					field: "createdAt",
					sortable: true,
					formatFn: (dt) => new Date(dt).toLocaleDateString(),
				},
				{
					label: "Updated",
					field: "updatedAt",
					sortable: true,
					formatFn: (dt) => new Date(dt).toLocaleDateString(),
				},
				{
					label: "",
					field: "action",
				},
      ],
      rows: [],
      serverParams: {
        columnFilters: {},
        sort: {
          field: "createdAt",
          type: "desc",
        },
        page: 1,
        perPage: 25,
      },
    };
  },
  mounted() {
    this.fetchItemsFromApi();
    setCurrentPageBreadcrumbs("Support Tickets", []);
  },
  methods: {
    ticketPriorityToColorClass(priority) {
      return TicketPriority[priority].colorClass;
    },
    ticketPriorityToStr(priority) {
      return TicketPriority[priority].label;
    },
    ticketStatusToColorClass(status) {
      return TicketStatus[status].colorClass;
    },
    ticketStatusToStr(status) {
      return TicketStatus[status].label;
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps };
    },
    onPageChange(params) {
      console.log("onPageChange", params);
      this.updateParams({ page: params.currentPage });
      this.fetchItemsFromApi();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.onPageChange({ currentPage: 1 });
    },
    onSortChange([params]) {
      this.updateParams({
        sort: {
          field: params.field,
          type: params.type,
        },
      });
      this.onPageChange({ currentPage: 1 });
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.onPageChange({ currentPage: 1 });
    },
    /**
     * Fetch models from API and display them in the table
     */
    fetchItemsFromApi() {
      this.isTableLoading = true;

      const tableSearch = {};
      console.log(this.serverParams.columnFilters);
      Object.keys(this.serverParams.columnFilters).forEach((e) => {
        if (!this.serverParams.columnFilters[e]) return;
        tableSearch[`${e}_contains`] = this.serverParams.columnFilters[e];
      });

      const getApiParams = {
        ...tableSearch,
        limit: this.serverParams.perPage,
        page: this.serverParams.page,
        sortBy: `${this.serverParams.sort.field}:${this.serverParams.sort.type}`,
        populate: "author",
      };

      ApiService.query("/tickets", { params: getApiParams })
        .then((res) => {
          this.rows = res.data.results;
          this.totalRecords = res.data.totalResults;
          this.isTableLoading = false;
        })
        .catch((err) => {
          this.isTableLoading = false;
        });
    },
    deleteItem(id) {
      const config = {
          responseToast: {
            text: "Ticket deleted successfully."
          }
        };
      this.isTableLoading = true;
      ApiService.delete(`/tickets/${id}`, config)
        .then((res) => {
          this.rows = this.rows.filter((e) => e.id !== id);
          this.isTableLoading = false;
        })
        .catch((err) => {
          this.isTableLoading = false;
        });
    },
    openNewSupportTicket() {
      this.$vfm.show("new-ticket-modal");
    },
    closeNewSupportTicketModal() {
      this.isNewSupportTicketModalOpen = false;
    },
    onSupportTicketCreated(createdSupportTicket) {
      this.$vfm.hide("new-ticket-modal");
      this.fetchItemsFromApi();
    },
  },
};
</script>

<style lang="scss" scoped>
.operations-table {
  background-color: #fff;
}
</style>